.calendarMonthRow {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.calendarMonthDay {
  height: 5em;
  border: 1px solid #eee;
}

.calendarMonthDay p {
  padding: 0em;
  margin: 0em;
  font-size: 0.8em;
}

.calendarMonthDay p.dayNumber {
  font-size: 1em;
  width: 25px;
  height: 25px;
  margin: 0.3em;
  padding: 0.1em;
  font-family: 'Nunito', sans-serif;
}

.calendarMonthDay div.currentDay {
  background-color: #b53f51;
}

.calendarMonthDay p.otherMonth {
  color: #ccc;
}

.calendarMonthRowHeader div {
  border: 1px solid #fff;
  background-color: #b5a33f;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 0.2em;
}

.calendarNavigationContainer {
  display: grid;
  grid-template-columns: 1fr 4fr 1fr;
  justify-items: center;
  align-items: center;
}

.capacity {
  text-align: center;
}
